import React from "react"
import Layout from "../layout/layoutPage"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  return (
    <Layout>
      <h1 className="text-4xl md:text-6xl font-semibold text-center m-4 md:m-10">
        404 NotFound
      </h1>
      <div className=" w-full md:w-1/3 lg:w-1/4 md:mx-auto">
        <GatsbyImage
          image={data.icon.childImageSharp.gatsbyImageData}
          alt="404 NotFound"
          className="overflow-hidden rounded-2xl mx-4"
        />
      </div>

      <p className="m-3 text-center font-medium md:text-2xl">
        Halamannya tidak di temukan silahkan kembali ke halaman utama
      </p>
      <Link to="/" className=" justify-center m-4 md:m-10 flex">
        <button className="rounded-lg text-center border-2 border-green-600 bg-green-400 font-semibold text-2xl px-2 py-1 text-white hover:bg-white hover:text-green-600">
          Home
        </button>
      </Link>
    </Layout>
  )
}

export default NotFound
